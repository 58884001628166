.questContainer {
    padding: 30px;
}

.questInfo {
    width: fit-content;
    margin: 20px 0 0 20px;
    font-size: 20px;
    font-weight: bold;
}

.questGridContainer {
    margin-top: 5%;
    padding: 0 100px;
}

.selectLabel {
    font-weight: 500;
    margin-bottom: 10px;
}

.checkboxSelectWrap {
    display: flex;
    align-items: center;
}

.keyboardFeaturesWrap {
    padding: 10px;
}

.keyboardFeatureName {
    font-weight: bold;
}

.keyboardFeatureInfo {
    font-size: 12px;
}

.checkboxWrap {
    display: flex
}

.questionBtnsWrap {
    margin-bottom: 30px;
}

.buttonLeft {
    float: left;
    margin-left: 20%;
    margin-top: 5%;
    margin-bottom: 5%;
}

.buttonRight {
    float: right;
    margin-right: 20%;
    margin-top: 5%;
    margin-bottom: 5%;
}

@media (max-width: 768px) {
    .questInfo {
        margin: 20px 0px;
    }

    .questGridContainer {
        margin-top: 20%;
        padding: 0px;
    }
}