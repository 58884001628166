.testPerformanceBar {
    display: flex;
    justify-content: space-between;
    color: white;
    align-items: center
}

.sentenceLabel {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

.sentenceProgressVal {
    font-size: 14px;
    font-weight: 500;
    margin-left: 20px;
    margin-bottom: 10px;
}

.wpmWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
}

.wpmLabel {
    font-size: 16px;
    font-weight: bold;
}

.wpmVal {
    font-weight: 500;
}

.paperStyle {
    width: 20%;
    padding: 10px;
    background: #008080 !important;
}

.progBarWrap {
    width: 20%;
    margin: 20px 0px 0px 20px;
    padding: 10px;
    background: #008080 !important;
}

.progTextWrap {
    display: flex; 
    justify-content: space-between; 
    align-items: center;
    color: white;
}

.progBarTxtHeading {
    font-size: 24px; 
    font-weight: 500; 
    color: white; 
    margin-bottom: 10px;
}

@media screen and (max-width: 600px) {
    .paperStyle {
        width: 95%;
    }

    .sentenceProgressVal {
        margin-left: 0px;
    }

    .progBarWrap {
        width: auto;
        margin: 0px;
    }
}