.resultsContainer {
    margin: 25px;
}

.continueCodeWrap {
    position: absolute;
    width: 25%;
    right: 10px;
    z-Index: 1000;
}

.graphHeading {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    padding: 1px;
    margin-top: 50px;
    color: #008080;
}

.contactUsHeading {
    color: #008080;
}

.contactUsWrap {
    padding: 5px;
    display: flex;
    flex-direction: row;
    font-size: 20px;
    margin-bottom: 10px;
}

.resultBarElement {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10px;
}

.resultBarElement:first-child {
    margin-left: 0;
}

.resultBarEleTitle {
    font-size: 18px;
    font-weight: bold;
    color: #008080
}

.resultBarEleVal {
    margin-left: 10px;
    font-size: 20px;
    font-weight: bold;
}

.resHighContainer {
    padding: 30px 70px;
    display: block;
    flex-direction: column;
}

.resHighEleWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.resHighEleHeading {
    color: #008080;
    font-size: 20px;
    font-weight: bold;
}

.errWpmWrap {
    display: flex;
    margin-top: 0px
}

.errWpmVal {
    color: #008080;
    font-size: 20px;
    font-weight: bold;
}

.resHighSentence {
    font-size: 20px;
    margin-top: 20px
}

.lineGraphContainer {
    width: 100%;
    margin: 70px 0px;
}

.resultBarWrap {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.leftArrow {
    position: fixed; 
    top: 50%;
    left: 0; 
    cursor: pointer;
}

.rightArrow {
    position: fixed; 
    top: 50%; 
    right: 0; 
    cursor: pointer
}

.loaderWrap {
    position: fixed; 
    top: 50%;
    left: 50%;
}

.continueTypTxt {
    font-size: 16px;
    font-weight: 500; 
    margin-bottom: 10px; 
    text-align: center;
}

.contCodeTxt {
    font-weight: bold; 
    margin-top: 25px;
}

.copyToClipWrap {
    border: 1px solid #ccc; 
    padding: 10px; 
    margin-bottom: 10px; 
    font-size: 36px; 
    font-weight: bold; 
    display: flex;
    justify-content: space-between;
}

.copyToClipIcon {
     width: 50px; 
     cursor: pointer;
}

.inputWrap {
    margin-bottom: 10px; 
    margin-top: 10px;
}

.emailInput {
    width: 98%;
    height: 40px;
    font-size: 22px;
    /* fontFamily: 'monospace' */
}

@media (max-width: 768px) {
    .continueCodeWrap {
        position: relative;
        width: 100%;
        right: 0px;
    }

    .graphHeading {
        font-size: 16px;
    }

    .contactUsWrap {
        flex-direction: column;
    }

    .resHighContainer {
        padding: 30px 0px;
        display: flex;
        flex-direction: column;
    }

    .resHighEleWrap {
        flex-direction: column;
    }

    .resHighEleHeading {
        font-size: 17px;
    }

    .errWpmWrap {
        margin-top: 5px;
    }

    .errWpmVal {
        font-size: 16px;
        font-weight: 500;
    }

    .resHighSentence {
        font-size: 17px;
        margin-top: 20px
    }

    .resultBarElement {
        margin-left: 0px;
        width: 100%;
        display: flex;
        margin-bottom: 10px;
        flex-direction: row;
        justify-content: space-between;
    }

    .resultBarEleTitle {
        font-size: 16px;
    }

    .resultBarEleVal {
        font-size: 14px;
    }

    .lineGraphContainer {
        margin: 0px;
    }

    .resultBarWrap {
        flex-direction: column;
    }
    
    .emailInput {
        width: 96%;
        font-size: 32px;
    }
}
