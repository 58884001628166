.homeHeader {
    font-size: 24px;
    font-weight: bold;
    margin: 10px 0px 20px 0px;
    padding-left: 45px;
}

.homeContentWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.infoWrap {
    margin-bottom: 15px;
    font-size: 16px;
}

.homeContainer {
    padding: 25px;
}

.langSelectWrap {
    width: fit-content;
    float: right;
}

.expIdInput {
    width: 95%;
    height: 40px;
    font-size: 26px;
    font-family: monospace;
}

.expIdInputWrap {
    margin-top: 20px; 
    display: flex;
    align-items: center;
}

.expIdText {
    font-size: 12px;
    margin-top: 5px; 
    text-align: left; 
    display: flex; 
    cursor: pointer;
}

.errText {
    color: red;
}

.startBtnWrap {
    margin-top: 50px;
}

.instructionHeading {
    font-weight: 500;
    font-size: 24px; 
    margin-bottom: 5px;
}

.instructionNote {
    margin-bottom: 5px; 
    color: red;
    font-weight: bold;
}

@media screen and (max-width: 600px) {
    .homeHeader {
        font-size: 24px;
        padding-left: 0;
    }

    .homeContentWrap {
        display: block;
    }

    .infoWrap {
        margin-top: 25px;
    }
}

/* Media query for screens with a minimum width of 1200 pixels */
/* @media screen and (min-width: 1200px) {
    .myDiv {
      width: 1200px; 
    }
  } */
