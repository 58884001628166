.appContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.logoImg {
    width: 250px;
    cursor: pointer;
}

.appPageContainer {
    flex-grow: 1;
}

