.testContainer {
    margin: 20px;
}

.testHeaderWrap {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
}

.testInstr {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
    text-align: left;
}

.testContentWrap {
    margin-top: 5%;
    height: 12px;
}

.testContent {
    position: relative;
    display: flex;
    justify-content: center;
}

.testInputSectionWrap {
    width: 70%;
    height: 112px;
    padding: 10px;
    font-family: monospace;
}

.testInputWrap {
    position: absolute;
    bottom: 10px;
    margin-top: 0px;
    width: inherit;
}

.testInput {
    width: 100%;
    height: 40px;
    font-size: 28px;
    font-family: monospace;
}

.nextBtnWrap {
    float: right; 
    margin-right: 14.5%; 
    margin-top: 3%;
}

@media (max-width: 768px) {
    .testInstr {
        font-size: 16px;
        margin-bottom: 30px;
        text-align: center;
    }

    .testHeaderWrap {
        flex-direction: column;
    }

    .testContentWrap {
        margin-top: 20%;
        height: auto
    }

    .testInputSectionWrap {
        width: 90%;
        height: auto;
        padding: 20px;
        font-family: monospace;
    }

    .testInputWrap {
        position: relative;
        margin-top: 10px;
    }

    .testInput {
        font-size: 20px;
    }
    
    .nextBtnWrap {
        margin-right: 0px;
    }
}